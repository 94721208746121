@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



#root{
  background-color: #f26f2b;
  background-image: url("images/artwork.png");
  background-repeat: repeat-y;
  background-size: 100% auto;
  font-family: 'DM Sans', sans-serif;
}

#root a{color:#f36f2b;     word-break: break-word;}
#root a.noLinkColour{color:inherit;}
#root .backCircle{display: block; margin: 0 0 20px 0;}
#root .page {
  height: 100vh;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

#root .page .title {
  background-color: #000;
  color: #ffffff;
  padding: 20px 0;
  display: flex;
  align-items: center;
  z-index: 200;
  justify-content: space-between;
}

#root .page .titleHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;
}
#root h1, #root h2, #root h3, #root h4, #root h5{
  font-family: 'DM Sans', sans-serif;
}
#root h1, #root h2{ color:#f26f2b;}

#root .contentGrey h2{ color:#000; }

#root .page .mainHeading{
  margin:0;
  font-size: 48px;
  font-family: 'DM Sans', sans-serif;
  min-height: 1rem;
    font-weight: bold;
    line-height: initial;
}

#root .page .subHeading {
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
      text-align: center;
}


#root .page .menuOpen {
  margin:20px;
}

#root .page .titleLogo {
  margin:20px;
  cursor: pointer;
      max-width: 20vw;
}

.backTopDiv{
  text-align: right;
    position: fixed;
    bottom: 0;
    padding: 20px;
}
#root .page .backToTopNav {
  cursor: pointer;
  color: #f26f2b;
  font-size: 32px;
}

h3 strong{color: #f26f2b;}
.contentIntroduction h3{    text-align: center; margin:50px 0;}
#root .contentIntroduction h3 a{ background-color: #f26f2b;
    color: #FFF;
    padding: 10px 20px;
    border-radius: 10px;}

#root .page .menu {
  flex-wrap: wrap;
  box-shadow: none;
  border: 0;
  background-color: #282220;
  z-index: 202;    margin: 0;
  width:auto;
  max-width: 350px;
  position: fixed;
  top:0;
  padding: 10px;
  font-size: 1.6rem;
  font-family: 'DM Sans', sans-serif;
  max-height: 100vh;
}

#root .page .menu.submenu {
  position: inherit;
}

#root .navigationScroll {
  max-height:calc(100vh - 32px - 40px);
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100%;
}

#root .menuLink{ color:#FFF;}

#root .ui.menu .item {
  color: #FFF;
  padding: 15px;
  border-radius:0;
  background-color: #282220;
}

#root .ui.menu .active.item {color:#f26f2b}
#root .menuClose{    font-size: 32px;}
#root .ui.menu .item.first{padding-top:30px;}

#root .tileContainer{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
#root .tile{
  width: calc( (100% / 3) - 20px );
  padding: 25px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px 0;
  min-height: 100px;
  background-color: #f36f2b;
}
#root a.tile{color:#FFF; font-weight: bold; font-size:18px}


#root #pageContainer{display:flex; flex-direction: column;  justify-content: space-between;       overflow-y: scroll;overflow-x: hidden; flex-grow: 1;}
#root .homeFooter{ padding: 20px; background: #272220; color: #FFF;max-width: 100%; margin:10px; clear: both;}
#root .credits {  padding-top: 1em; display:flex; }
.creditsImage{height:155px; width:251px; margin: 0 1em 1em 0;}

#root .ui.card{    padding: 10px 0;
    width: 100%;
    background: none;
    border: none;
    box-shadow: none;}

.contentSegment{   padding: 20px; margin: 10px;}
.contentIntroduction{   padding: 0 20px; margin: 0 10px;}
.contentGrey{padding: 30px; margin: 0 0 20px 0; background-color: #f2f2f2;}
.contentCentre{display: flex; justify-content: center;}
.marginTop{margin-top:20px;}

.contentHeaderSegment span { color: #FFF!important;}
.contentHeaderSegment a { color: #FFF!important; text-decoration: underline!important;}
#root .linkIcon{margin-left: 10px;}
.invisible{display: none;}

.itemContent::after{content:' '; display: block; clear:both;}
.itemContent img{float:right;     max-width: 20vw;     margin: 0 0 30px 30px;}

.searchInput{width:100%; margin-bottom: 20px;}
.pageIdentifier{font-size: 12px;}
#root a.searchLink{color: #000; }

@media only screen and (min-width: 768px) and (max-width: 991px){
#root .homeFooter{width: 723px!important;}

}

@media only screen and (min-width: 992px) and (max-width: 1199px){
#root .homeFooter{
    width: 933px!important;
  }
}

@media only screen and (max-width: 767px) {
  .backTopDiv {right: 0; padding:5px;}
  #root .page, #root .page .title { margin:0!important; width:100%!important;}
  #root .homeFooter{width: auto;}
  #root .homeFooter{position: inherit;}
  #root .home-page .menu {height:30vh;}
  #root .credits {flex-direction: column; align-items: center; text-align: center;}
    .creditsImage{margin: 0 0 1em 0;}
  #root .page .mainHeading {font-size: 24px;}
  #root .tile {width:100%;}
  .itemContent img{float: none; max-width: 100%;      margin: 0 0 30px 0;}

}

@media only screen and (max-width: 500px) {
  #root .page .menu {max-width: none; width: 100%;}
  #root .ui.menu .item {padding: 10px;}
}
